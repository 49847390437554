<template>
    <div class="header_content">
        <div class="leaning_header_content">

            <div class="leaning_header_title_children">
                <div>梯到首页</div>
                <div>
                    <el-dropdown>
                        <span class="el-dropdown-link">
                            <div style="display: flex; align-items: center;">
                                <el-icon>
                                    <User />
                                </el-icon>admin
                                <el-icon class="el-icon--right">
                                    <arrow-down />
                                </el-icon>
                            </div>
                        </span>
                        <template #dropdown>
                            <el-dropdown-menu>
                                <el-dropdown-item>&nbsp;&nbsp;退出&nbsp;&nbsp;</el-dropdown-item>
                            </el-dropdown-menu>
                        </template>
                    </el-dropdown>
                </div>
            </div>

            <!-- <div class="leaning_header_text">

</div> -->
        </div>
        <div class="leaning_header_text">
            <div style="display: flex; align-items: center;">
                <div class="header_text_title_img">
                    梯到学习中心
                </div>
                <div class="header_text_title">
                    <div :class="state==item.id?'active':''" v-for="(item,index) in titles" :key="item.id" @click="qiehuan(item.id,item.path)">{{ item.name }}</div>
                   
                </div>
            </div>
            <div>
                <el-input type="text" style="width: 205px;" ></el-input><el-button type="primary">搜索</el-button>
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref, reactive } from 'vue'
import { ArrowDown, User } from '@element-plus/icons-vue'
import { useRouter } from 'vue-router'
const titles = ref([
    {
        id:"1",
        name:"首页",
        path:"/leaningindex"
    },
    {
        id:"2",
        name:"全部课程",
        path:"/allcourse"
    },
    {
        id:"3",
        name:"学习计划",
        path:"/leaningplan"
    },
    // {
    //     id:"4",
    //     name:"人才认证",
    //     path:"/authentication"
    // }
])
// 实例化router
const router = useRouter()

const state = ref(titles.value[0].id)
const qiehuan = (id,path)=>{
    state.value = id
    router.push({
        path:path
    })
}
</script>

<style lang="scss" scoped>
.header_content {
   
    .leaning_header_content {
        width: 100%;
        display: flex;
        justify-content: center;

        .leaning_header_title_children {
            width: 68%;
            height: 36px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            color: #657180;
        }
    }

    .leaning_header_text {
        width: 100%;
        height: 10em;
        padding: 0px 24em;
        box-sizing: border-box;
        background-color: white;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .header_text_title_img{
            font-size: 1.8em;
            font-family:'楷体';
        }
        .header_text_title{
            margin-left: 5em;
            display: flex;
            width: 38em;
            div{
                flex: 1;
                font-size: 1.3em;
                cursor: pointer;
                text-align: center;
                height: 4em;
                line-height: 4em;
                padding: 0px 8px;
            }
        }
    }
}


.example-showcase .el-dropdown-link {
    cursor: pointer;
    color: var(--el-color-primary);
    display: flex;
    align-items: center;
}
.active{
    color: #3768fa;
    border-bottom: 2px solid #3768fa;
}
</style>